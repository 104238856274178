oasrs.promoter = {

    request: function() {

        var updateDescription = function(){
            var $el = $('#type'),
                value = $el.val(),
                description = typeof handover.descriptions[value] !== 'undefined' ? handover.descriptions[value] : '--';

            if (value !== '') {
                $('#contest-type-description').text(description).closest('div').show();
            } else {
                $('#contest-type-description').closest('div').hide();
            }
        };

        $('#type').change(function(){
            updateDescription();
        });

        updateDescription();
    }
};