oasrs.profile = {

    init: function() {

        var showCurrentPassword = function(show) {
            if (show) {
                $('#current_password').closest('.row').slideDown('fast');
            } else {
                $('#current_password').closest('.row').slideUp('fast');
            }
        };

        $('#new_email, #new_email_confirmation').change(function(){
            var mustConfirm = false;
            $('#new_email, #new_email_confirmation').each(function(i,el){
                if ($(el).val() !== '') {
                    mustConfirm = true;
                }
            });
            showCurrentPassword(mustConfirm);
        });

        $('#password, #password_confirmation').change(function(){
            var mustConfirm = false;
            $('#password, #password_confirmation').each(function(i,el){
                if ($(el).val() !== '') {
                    mustConfirm = true;
                }
            });
            showCurrentPassword(mustConfirm);
        });
    }
};
