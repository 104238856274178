oasrs.notifications = {

    init : function() {

        var updateNotificationsBadge = function(n) {

            var $badge = $('.header-login .btn-alerta .ball');

            if ($badge.length) {
                var count = parseInt($badge.text(), 10),
                    newCount = count+n;
                $badge.text(newCount);
                if (newCount > 0) {
                    $badge.removeClass('hidden');
                }
                else {
                    $badge.addClass('hidden');
                }
            }
        };

        $('.toggle-notification-status').click(function(){
            var $li = $(this).closest('li');
            var url = $li.hasClass('read') ? handover.notifications.markAsUnreadUrl : handover.notifications.markAsReadUrl;

            $.post(url, {
                _token: handover.notifications._token,
                association: handover.notifications.association,
                notification_id: $(this).data('notification-id')
            });

            updateNotificationsBadge($li.hasClass('read') ? 1 : -1);

            $li.toggleClass('read');
        });
    }
};
