/*var oasrsNyanCat = function() {
    var html = '<div class="cat"></div><audio autoplay><source src="../../audio/nyancat.ogg"></audio>';
    var css =   '@keyframes backg { 0% {opacity:0;} 5% {opacity:0.5;} 95% {opacity:0.5;} 100% {opacity:0;} }'+
                '@keyframes gocat { 0% {left:-100%;} 10% {left:-10%;} 90% {left:-10%;} 100% {left:-100%;} }'+
                '.nyancat{position:fixed; top:0px; width:100%; height:100%; z-index:99999;}'+
                '.nyancat .backgrd{position:absolute; top:0px; left:0px; width:100%; height:100%; background-color:#000; z-index:0; animation:backg 7000ms ease-in-out; opacity:0;}'+
                '.nyancat .cat{position:absolute; width:90%; height:800px; top:-100%; bottom:-100%; left:-100%; margin:auto; background-image:url("../../img/nyancat.gif"); background-size:contain; background-repeat:no-repeat; animation:gocat 7000ms ease-in-out; z-index:5;}';

    $('body').append('<div class="nyancat"><div class="backgrd"></div>'+html+'<style>'+css+'</style></div>');
    setTimeout( function(){
        $('*').each(function(){
            var hue = 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';
            $(this).css('color', hue);
        });
    }, 3500);
    setTimeout( function(){
        $('.nyancat').remove();
    }, 7500);
};*/

