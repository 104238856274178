oasrs.map = {

    init : function() {

        var map;
        var geocoder = new google.maps.Geocoder();
        var bounds = new google.maps.LatLngBounds();

        /*          MAP          */
        // Estilos para um mapa bonito
        var mapStyles = [
            {"featureType":"landscape","stylers":[{"saturation":-100},{"lightness":60},{"visibility":"on"}]},
            {"featureType":"poi","stylers":[{"saturation":-100},{"lightness":70},{"visibility":"simplified"}]},
            {"featureType":"road.highway","stylers":[{"saturation":-100},{"visibility":"simplified"}]},
            {"featureType":"road.arterial","stylers":[{"saturation":-100},{"lightness":30},{"visibility":"on"}]},
            {"featureType":"road.local","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},
            {"featureType":"transit","stylers":[{"saturation":-100},{"visibility":"simplified"}]},
            {"featureType":"administrative.province","stylers":[{"visibility":"off"}]},
            {"featureType":"water","elementType":"geometry","stylers":[{"color":"#c6c6c6"}]}
        ];

        //Definicoes do mapa
        var mapOptions = {
            zoom:7,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: new google.maps.LatLng(0, 0),
            styles: mapStyles,
            scrollwheel: true, //fazer zoom com o mouse well
            //disableDoubleClickZoom: 'disables', //duplo click para fazer zoom
            draggable: true,
            panControl: true, //setas de andar para os lados
            panControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            zoomControl: true, //mais e menos do zoom
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL,
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            //scaleControl: false, //traco de escala
            streetViewControl: false, //boneco do street view
            streetViewControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            disableDefaultUI: true,
            moveTo: { point: google.maps.ZoomControlStyle.SMALL }
        };

        var mcOptions = {
            gridSize: 50,
            maxZoom: 11,
            clusterClass: 'cluster',
        };

        // Criar class no ballon
        function balon(cls) {
            $('.gm-style-iw').prev().find('div:eq(1), div:last()').addClass('ballon');
            $('.gm-style-iw').children().addClass('ballon-conten-info');
            $('.gm-style-iw').next().addClass('ballon-close');
            $('.gm-style-iw').parent().removeAttr('class').addClass('area-infowindow '+cls+'');
        }

        function initializeMap() {
            //gerar o map
            map = new google.maps.Map(document.getElementById("mapoasrs"), mapOptions);
            mc = new MarkerClusterer(map, gmarkers, mcOptions);
            infowindow = new google.maps.InfoWindow();

            var mapWindows = [];
            var gmarkers = [];
            $.each(handover.mapItems, function() {
                var t = this;
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(t.lat, t.lng),
                    map: map,
                    title: t.mapCategory,
                    icon: t.icon,
                    zIndex: 1,
                });
                var dateHeading = t.mapCategory == 'other' ? 'Data' : (t.mapCategory == 'open' ? 'Data de entrega' : 'Data de fecho');
                var tpl = '<div class="'+t.mapCategory+'"><h4>'+t.mapHeading+'</h4><h2>'+t.title+'</h2><p><icon class="fa fa-plus"></icon> '+dateHeading+': <span>'+t.generalDate+'</span></p><ul>'+t.info+'</ul><a href="'+t.permalink+'">Ver detalhe <icon class="fa fa-chevron-right"></icon></a></div>';
                mapWindows.push(infowindow);
                gmarkers.push(marker);

                google.maps.event.addListener(marker, 'click', function() {
                    infowindow.setContent(tpl);
                    infowindow.open(map, marker);
                    var fakePos = new google.maps.LatLng(Number(t.lat) + 0.010, Number(t.lng));
                    map.panTo(fakePos);
                    map.setZoom(14);
                    balon( t.mapCategory );
                    $(mapWindows).each(function() {
                        if (this !== infowindow) {
                            this.close();
                        }
                    });

                });
                google.maps.event.addListener(map, 'idle', function() {
                    balon( t.mapCategory );
                });    
                bounds.extend(marker.position);
            });

            onClickShow = function(categ, elem) {
                $('.btn').removeClass('active');
                $(elem).addClass('active');
                infowindow.close();
                mc.setStyles( [{url:'/img/cluster-'+categ+'.png', height:45, width:45, fontFamily:'AtlasG Medium', textColor:'#ffffff', textSize:20 }] );
                for (var i=0; i<gmarkers.length; i++) {
                    gmarkers[i].setVisible(false);
                    mc.removeMarker(gmarkers[i]);
                    if ('all' == categ) {
                        gmarkers[i].setVisible(true);
                        mc.addMarker(gmarkers[i]);
                    } else {
                        if (gmarkers[i].title == categ) {
                            gmarkers[i].setVisible(true);
                            mc.addMarker(gmarkers[i]);
                        }
                    }

                }
            };

            $('#map .btn').first().click();

            //map.fitBounds(bounds);

            geocoder.geocode({'address':'portugal'}, function(results) {
                var pos = results[0].geometry.location;
                var ww = $(window).width()/900;
                var wh = $(window).height()/4000;
                map.setCenter({
                    lat: pos.lat()+wh,
                    lng: pos.lng()+ww
                });
            });
        }

        if ($('#mapoasrs').length) {
            google.maps.event.addDomListener(window, 'load', initializeMap);
        }
    }
};
