oasrs.contests = {

    submission : function() {

        var $method = $('.coordination-method');
        var updateCoordinationMethodFeedback = function() {
            $('.feedback', $method).html($('input[name="method"]:checked', $method).data('feedback'));
        };

        var showHideCoordinationDetails = function(label) {
            if (typeof label === 'undefined') {
                label = $('input[name="method"]:checked', $method).val();
            }
            $('.areaform').hide().find('input').prop('disabled', true);
            $('.areaform.'+label).show().find('input').prop('disabled', false);
        };

        showHideCoordinationDetails();
        updateCoordinationMethodFeedback();

        $('input[name="method"]', $method).change(function(){
            updateCoordinationMethodFeedback();
            showHideCoordinationDetails($(this).val());
        });

        $('.toggable-cell').click(function(){
            $('input', this).prop('checked', true);
            $(this).closest('.row').find('.toggable-cell').removeClass('active');
            $(this).addClass('active');
        });

        var $partnersList = $('#partners-list-wrapper');
        var $partnersFeedback = $partnersList.find('.feedback');

        $partnersList.taggable({
            autocomplete: true,
            autocompleteOptions: {
                source: function( request, response ) {
                    $.get($('input[name="partner_search_toggle"]:checked').data('url'), {term: request.term}, function(data) {
                        response(data);
                    });
                },
                focus: function(event, ui) {
                    event.preventDefault();
                    $(event.target).val(ui.item.label);
                },
                response: function(event, ui) {
                    if (ui.content.length === 0) {
                        $partnersFeedback.text('Nenhum membro ou organização encontrado.');
                    } else {
                        $partnersFeedback.text($partnersFeedback.data('original'));
                    }
                }
            },
            minLength: 3,
            add: function(tag, value, label){
                tag.append('<input type="hidden" name="partners[]" value="'+value+'"/>');
            },
            update: function(values) {
                $('.list-title', $partnersList).toggle(Boolean(values.length));
            }
        });

         $partnersList.find('input')
            .on('keyup', function(){
                if ($(this).val().length < 3) {
                    $partnersFeedback.text($partnersFeedback.data('original'));
                }
            })
            .on('change', function(){
                $partnersFeedback.text($partnersFeedback.data('original'));
            });

        $('section').on('click', '.remove-member', function(){
            var $member = $(this).closest('.toggable-member');
            $('input', $member).prop('disabled', function(i, v) { return !v; });
            $member.toggleClass('disabled');
            $('.member-field input:not(":disabled")', $member).focus();
        });

        $('.add-member').click(function(){
            var slug = $(this).data('template'),
                $wrapper = $('.'+slug+'-list'),
                $template = $('.'+slug+'-member-template'),
                $clone = $($template.html());

            $clone.find('input, select').prop('disabled', false);
            $clone.appendTo($wrapper);
        });

    }
};
