oasrs = {};

$(function(){

    oasrs.common.init();
    oasrs.notifications.init();

    $body = $('body');

    if ($body.hasClass('member-register')) {
        oasrs.authOther.init();
    }

    if ($body.hasClass('profile-info')) {
        //oasrs.profile.init();
        oasrs.authOther.init();
        oasrs.showcase.edit();
    }

    if ($body.hasClass('profile-access')) {
        oasrs.profile.init();
    }

    if ($body.hasClass('profile-teams')) {
        oasrs.teams.manage();
    } else if ($body.hasClass('profile-teams-create') || $body.hasClass('profile-teams-edit')) {
        oasrs.teams.edit();
        oasrs.showcase.edit();
    }

    if ($body.hasClass('contests-map')) {
        oasrs.map.init();
    }

    if ($body.hasClass('contest-submission')) {
        oasrs.contests.submission();
    }

    if ($body.hasClass('showcase')) {
        oasrs.showcase.index();
        oasrs.showcase.showcaseIndex();
    }

    if ($body.hasClass('showcase-projects')) {
        oasrs.showcase.index();
    }

    if ($body.hasClass('showcase-search')) {
        oasrs.showcase.searchForms();
    }

    if ($body.hasClass('request-proposal')) {
        oasrs.promoter.request();
    }

    if ($body.hasClass('contest-assessment') || $body.hasClass('contest-assessment-detail') || $body.hasClass('contest-generate-report') || $body.hasClass('contest-submission-report')) {
        oasrs.assessment.init();
        oasrs.assessment.dates();
        oasrs.assessment.popovers();
    }

    if ($body.hasClass('contest-finish-report')) {
        oasrs.assessment.report();
    }

});
