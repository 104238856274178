oasrs.showcase = {

    searchForms : function() {
        $('input[name="search_type"]').change(function(){
            $('.search-type').addClass('hidden');
            $('.search-'+$(this).val()).removeClass('hidden');
        });
    },

    index : function() {

        $('.toggle-filter').click(function(e){
            e.preventDefault();
            $('.filter').toggle();
        });

        $('.list-style .display .imag').on('click', function() {
            $('.list-style .display .noimag').removeClass('active');
            $(this).addClass('active');
            $('.thumb .image-wrapper').removeClass('noimag').addClass('imag');
            resizeImg($('.thumbnail .imag'));
            Cookies.set('showcaseStyle', 'imag', { expires: 365 });
        });
        $('.list-style .display .noimag').on('click', function() {
            $('.list-style .display .imag').removeClass('active');
            $(this).addClass('active');
            $('.thumb .image-wrapper').removeClass('imag').addClass('noimag');
            Cookies.set('showcaseStyle', 'noimag', { expires: 365 });
        });
    },

    showcaseIndex : function() {

        $('body.showcase .thumb')
            .popover({
                trigger: 'manual',
                viewport: 'body',
                placement: 'auto left',
                html: true,
                content: function(){
                    return $('.info-box', this).html();
                },
            }).on('click', function (e) {
                var pop = this;
                $('.thumb').popover('hide');
                setTimeout( function() { $(pop).popover('show'); }, 300);
            });

        $(document).on('click','.popover .close', function() {
            $('.thumb').popover('hide');
        });

        var closeMenu = function(e){
            var container = $('.popover');
            if (typeof e === 'undefined' || (!container.is(e.target) && container.has(e.target).length === 0)){
                $('.thumb').popover('hide');
            }
        };

        $(document).mouseup(function(e){
            closeMenu(e);
        });
    },

    edit: function() {

        $multiSelects = $('.select-multiple select');

        $('#in_showcase').change(function(){
            if ($(this).is(':checked')) {
                $('#showcase-fields').slideDown('fast');
                $multiSelects.trigger("chosen:updated");
            } else {
                $('#showcase-fields').slideUp('fast');
            }
        });

        if ($multiSelects.length) {
            $multiSelects.chosen({
                width: '100%',
                allow_single_deselect: true,
                no_results_text: "Nenhum resultado encontrado com "
            });
        }

        $('[name="redirect"]').click(function(){
            var $form = $(this).closest('form');
            $form.append('<input type="hidden" name="redirect" value="'+$(this).val()+'"/>');
            $form.submit();
        });
    }
};