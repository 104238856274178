
/*Resize para imagens*/
function resizeImg(elem) {
    $('img', elem).each( function(){  
        var scope = $(this);
        if ( !scope.parent('.resizeimg').length ) {
            scope.wrap( "<div class='resizeimg'></div>" );
        }
        var r = 0;
        var mw = scope.parent('.resizeimg').width();
        var mh = scope.parent('.resizeimg').height();
        var w = scope.width();
        var h = scope.height();
        var mr = mh/mw;
        var cr = h/w;
        scope.css({position:'absolute',margin:'auto',top:'-100%',right:'-100%',bottom:'-100%',left:'-100%',padding:'0'});
        if(cr === mr){
            scope.css({height: '100%'});
            scope.css({width: '100%'});
        }else if(cr >= mr){
            r = mw/w;
            scope.css({width: mw});
            scope.css({height: h*r});
        }else if(cr <= mr){
            r = mh/h;
            scope.css({height: mh});
            scope.css({width: w*r});
        }
    });
}
