oasrs.teams = {

    edit: function() {

        var $membersList = $('#members-list-wrapper');
        var $membersFeedback = $membersList.find('.feedback');

        $membersList.taggable({
            autocomplete: true,
            autocompleteOptions: {
                source: handover.urls.searchMembers,
                focus: function(event, ui) {
                    event.preventDefault();
                    $(event.target).val(ui.item.label);
                },
                response: function(event, ui) {
                    if (ui.content.length === 0) {
                        $membersFeedback.text('Nenhum membro encontrado.');
                    } else {
                        $membersFeedback.text($membersFeedback.data('original'));
                    }
                }
            },
            minLength: 3,
            add: function(tag, value, label){
                tag.append('<input type="hidden" name="members[]" value="'+value+'"/>');
            },
            update: function(values) {
                $('.list-title', $membersList).toggle(Boolean(values.length));
            }
        });

         $membersList.find('input')
            .on('keyup', function(){
                if ($(this).val().length < 3) {
                    $membersFeedback.text($membersFeedback.data('original'));
                }
            })
            .on('change', function(){
                $membersFeedback.text($membersFeedback.data('original'));
            });

        var $invitationList = $('#invitation-list-wrapper');
        var $invitationFeedback = $invitationList.find('.feedback');
        $invitationFeedback.hide();

        $invitationList.taggable({
            add: function(tag, value, label){
                var emailRegex = /\S+@\S+\.\S+/;
                if (emailRegex.test(value) === false) {
                    $invitationFeedback.show();
                    return false;
                }
                $invitationFeedback.hide();
                tag.append('<input type="hidden" name="invitations[]" value="'+value+'"/>');
            },
            update: function(values) {
                $('.list-title', $invitationList).toggle(Boolean(values.length));
            }
        });

         $invitationList.find('input')
            .on('change', function(){
                $invitationFeedback.hide();
            });
    },

    manage: function() {
        $('.remove-team').click(function(){
            if (confirm('Deseja mesmo remover a organização "'+$(this).closest('.team-row').find('.team-name').text()+'"? Não é possível desfazer esta ação.')) {
                $(this).closest('.team-row').find('form').submit();
            }
        });

        $('.remove-from-team a').click(function(e){
            e.preventDefault();
            if (confirm('Deseja mesmo remover-se da organização "'+$(this).closest('.team-row').find('.team-name').text()+'"? Não é possível desfazer esta ação.')) {
                $(this).closest('.team-row').find('form').submit();
            }
        });
    }
};