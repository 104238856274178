var hh = 0;
$(window)
    .on('load', function() {
        hh = $('header').innerHeight();
        var h = window.location.hash.substring(1);
        if (h !== '') {
            scrollAnchor(100, h, hh);
        }
        $('.tabs').hptabs();
        creatHref();
        fixedSubMenuTabs();
        resizeImg( $('.slider-cell') );
    })
    .on('resize', function() {
        hideAlert();
        creatHref();
        resizeImg( $('.slider-cell') );
    })
    .on('scroll', function() {
        fixedMenu();
        fixedSubMenu();
    });

function hideAlert() {
    if ( $('body').width() <= 991 ){
        $('body').removeClass('no-scroll');
        $('header').removeClass('open-alert');
    }
}

function fixedMenu () {
    var wtop = $(this).scrollTop();
    if (wtop >= 10 ) {
        $('header, main').addClass('fixed');
    } else {
        $('header, main').removeClass('fixed');
    }
}

function fixedSubMenu() {
    if( $('.sub-menu').length ) {
        var sub = $('.sub-menu').offset().top-hh;
        var wtop = $(this).scrollTop();
        var th = $('#billhead').height();
        if(wtop >= sub) {
            $('.sub-menu').addClass('fixed');
        }
        if(wtop <= th) {
            $('.sub-menu').removeClass('fixed');
        }
        $('.area').each(function(i) {
            var area = $(this).offset().top;
            if (wtop >= area - 300) {
                $('.sub-menu li a.active').removeClass('active');
                $('.sub-menu li a').eq(i).addClass('active');
            }
        });
    }
}

function fixedSubMenuTabs() {
    if( $('.sub-menu-tabs').length ) {
        $('.sub-menu-tabs a').on('click', function(){
            var anchor = $(this).attr('href').split('#')[1];
            $('.sub-menu-tabs a, #area .container').removeClass('active');
            $('#area .container[data-anchor="'+anchor+'"]').addClass('active');
            $(this).addClass('active');
        });
        if(window.location.hash) {
            var hash = document.location.hash.substring(1);
            $('.sub-menu-tabs li a[href*=#'+hash+']').click();
        } else {
            $('.sub-menu-tabs li:first-child a').click();
        }
    }
}

function scrollAnchor(time, anchor, less){
    if( $('[data-anchor="'+anchor+'"]').length ) {
        $('html,body').stop().delay(time).animate({scrollTop: $('[data-anchor="'+anchor+'"]').offset().top-less }, 500);
    }
    var path = document.location.pathname;
    setTimeout( function(){ if(anchor==='home'){ history.replaceState({}, document.title, path); } },0);
}

$.fn.hptabs = function(options){
    var t = $(this);
    $('.tab > a', t).on('click', function(e){
        e.preventDefault();
        var tab = $(this).closest('.tab');
        if ( t.hasClass('multi') ){
            if( tab.hasClass('active') ){
                tab.removeClass('active');
            } else {
                tab.addClass('active');
            }
        } else {
            if( tab.hasClass('active') ){
                $('.tab', t).removeClass('active');
            } else {
                $('.tab', t).removeClass('active');
                tab.addClass('active');
            }
        }
    });
    $(window).on('load', function(){
        $('.tab:first-child a', t).click();
    });
};

function creatHref() {
    if(window.innerWidth <= 991 ){
        $('.thumb .creathref').each( function(){
           $(this).attr('href', $(this).data('href') );
        });
    } else {
        $('.thumb .creathref').each( function(){
           $(this).removeAttr('href');
        });
    }
}

var $slider = $('.slider').flickity({
    cellAlign: 'left',
    pageDots: false,
    selectedAttraction: 0.18,
    friction: 0.8,
    previous: true,
    next: true,
    wrapAround: true
});

var $sliderData = $slider.data('flickity');
if( $('.slider').length ) {
    $sliderData.on( 'cellSelect', function() {
        var select = $sliderData.selectedIndex+1;
        var numer = $sliderData.cells.length;
        $('.flickity-conter .select').text(select);
    }); $sliderData.select(0);
    $('.flickity-enabled').append('<div class="flickity-conter"><span class="select">'+($sliderData.selectedIndex+1)+'</span>/<span class="length">'+$sliderData.cells.length+'</span></div>');
    if( $('.slider .slider-cell').length <= 1 ) {
        $('.flickity-prev-next-button, .flickity-conter').hide();
        $slider.flickity('unbindDrag');
    }
    if( $('#slider-studies').length || $('#slider-work').length ) {
        $slider.flickity({wrapAround: false});
    }
}




