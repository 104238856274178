oasrs.assessment = {

    init: function() {
        $('.data .item').on('click', function() {
            $(this).toggleClass('active');
            var $item = $(this);
            var jqxhr = $.post($item.hasClass('active') ? handover.meetingDates.approveDate : handover.meetingDates.rejectDate, {
                _token: handover.meetingDates._token,
                date: $item.data('date')
            }, function(response) {
                // ...
            })
            .fail(function() {
                alert('Ocorreu um erro. Por favor tente novamente.');
            });
        });

        $('.div-tabela .criterion')
            .popover({
                trigger: 'manual',
                viewport: '.div-tabela',
                placement: 'auto left',
                html: true,
                content: function(){
                    return $('.info-box', this).html();
                },
            }).on('click', function (e) {
                var pop = this;
                $('.criterion').popover('hide');
                setTimeout( function() { $(pop).popover('show'); }, 300);
            });

        $(document).on('click','.popover .close', function() {
            $('.criterion').popover('hide');
        });

        var closeMenu = function(e){
            var container = $('.popover');
            if (typeof e === 'undefined' || (!container.is(e.target) && container.has(e.target).length === 0)){
                $('.criterion').popover('hide');
            }
        };

        $('.div-tabela').scroll( function(){
            $('.criterion').popover('hide');
        });

        $(document).mouseup(function(e){
            closeMenu(e);
        });

        var calculateAverage = function(cell){
            var values = [],
                validAverage = true,
                row = cell.closest('tr');
            row.find('input.valor').each(function(i,input){
                var pattern = new RegExp($(input).attr('pattern')),
                    value = $(input).val() !== '' ? $(input).val() : $(input).data('fallback'),
                    valid = pattern.test(value) && parseInt(value) >= 0 && parseInt(value) <= 20;
                $(input).toggleClass('error', !valid);
                if (!valid) {
                    values.push(0);
                    validAverage = false;
                    return true;
                }
                values.push($(input).data('weight')*value);
            });
            var total = values.reduce(function(a, b){
                return a+b;
            });
            // Check if number can be rounded
            total = Math.round(total) === total ? total : total.toFixed(2);
            row.find('.final-average').text(validAverage ? total+'pts' : '--');
            $('.invalid-ratings').toggleClass('hidden', validAverage);
        };

        $('input.valor').change(function () {
            calculateAverage($(this));
        });

        $('#distinction').change(function(){
            $('.new-category-wrapper').toggleClass('hidden', $(this).val() !== 'new_category');
        });

        $('.confirmed').click(function(event){
            if (!confirm($(this).data('confirm-text'))) {
                return event.preventDefault();
            }
        });

        $('.toggle-submission-comments').click(function(event){
            event.preventDefault();
            $('table').toggleClass('with-comments');
            $(this).toggleClass('showing');
        });
    },

    report: function () {

        $('.download-report .download').click(function(event){
            event.preventDefault();
            var $anchor = $(this),
                $form = $anchor.closest('form');
            $.post($form.attr('action'), $form.serialize(), function(data){
                window.location.href = $anchor.attr('href');
            });
        });
    },

    dates: function () {

        if ($('#new-date').length) {
            var minDate = $('#new-date').data('minDate'),
                maxDate = $('#new-date').data('maxDate');
            $('#new-date').datetimepicker({
                dateFormat: 'yy-mm-dd',
                minDate: minDate,
                maxDate: maxDate
            });

            $('.dates-box .add-new a').click(function (event) {
                event.preventDefault();
                $('.add-new-wrapper').slideUp('fast');
                $('.new-date-wrapper').slideDown('fast', function () {
                    $('.new-date-wrapper input').focus();
                });
            });

            $('.new-date-wrapper .plus').click(function (event) {
                event.preventDefault();
                $('.new-date-wrapper').slideUp('fast', function () {
                    var $form = $('.new-date-wrapper');
                    var jqxhr = $.post($form.attr('action'), $form.serialize(), function(response) {
                        var newRow = handover.meetingDates.template
                                        .replace(':raw', $('#new-date').val())
                                        .replace(':display', response)
                                        .replace(':count', '0 membros');

                        $('.dates-box .add-new').before(newRow);
                    })
                    .fail(function() {
                        alert('Ocorreu um erro. Por favor tente novamente.');
                    });
                    $('#new-date').datepicker('hide');
                });
                $('.add-new-wrapper').slideDown('fast');
            });

            $('.dates-box').on('click', '.remove-date', function () {
                if (confirm('Deseja mesmo remover esta data? Não é possível desfazer esta ação.')) {
                    var row = $(this).closest('.date-row');
                    var jqxhr = $.post(handover.meetingDates.removeDate, {
                        _token: handover.meetingDates._token,
                        date: row.data('date')
                    }, function() {
                        row.remove();
                    })
                    .fail(function() {
                        alert('Ocorreu um erro. Por favor tente novamente.');
                    });
                }
            });

            $('.dates-box').on('click', '.set-date', function () {
                if (confirm('Deseja mesmo marcar a reunião para esta data?')) {
                    var jqxhr = $.post(handover.meetingDates.setDate, {
                        _token: handover.meetingDates._token,
                        date: $(this).closest('.date-row').data('date')
                    }, function(response) {
                        $('.meeting-date-text').text(response);
                        $('.meeting-dates-wrapper').addClass('scheduled');
                    })
                    .fail(function() {
                        alert('Ocorreu um erro. Por favor tente novamente.');
                    });
                }
            });

            $('.reset-date').click(function (event) {
                event.preventDefault();
                if (confirm('Deseja mesmo modificar a data da reunião?')) {
                    var jqxhr = $.post(handover.meetingDates.resetDate, {
                        _token: handover.meetingDates._token
                    }, function(response) {
                        $('.meeting-dates-wrapper').removeClass('scheduled');
                    })
                    .fail(function() {
                        alert('Ocorreu um erro. Por favor tente novamente.');
                    });
                }
            });
        }
    },
    popovers: function(){
        console.log('popovers');
        var options = {
            container: 'body',
            template: '<div class="popover assessment-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title">title</h3><div class="popover-content"></div><div class="close"><i class="fa fa-times-circle" aria-hidden="true"></i></div></div>'
        };

        $('[data-toggle="popover"]').popover(options).on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                var pop = this;
                $(this).popover('hide');
                setTimeout( function() { $(pop).popover('show'); }, 300);
            });

        $('.popover-close').on('click', function(el){
            console.log('close this shit');
        });

        $(document).on('click','.popover .close', function() {
            $(this).parent().popover('hide');
        });


    }
};
