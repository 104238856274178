oasrs.common = {

    init : function() {

        $('menu li a[href*=#], .sub-menu li a[href*=#]').on('click', function(event){
            hh = $('header').innerHeight();
            var h = this.hash.substring(1);
            scrollAnchor(100, h, hh);
            $('header').removeClass('open-menu');
        });

        /* menu hanburger */
        $('.burger').on('click', function() {
            $('header').toggleClass('open-menu');
        });

        /* btn alerta */
        $('.btn-alerta').on('click', function() {
            //$('body').toggleClass('no-scroll');
            $('header').toggleClass('open-alert');
        });

        /*  box-login open */
        $('.btn-login').on('click', function() {
            $('.box-login').addClass('show');
        });

        /*  box-login close */
        $('.box-login .close').on('click', function() {
            $('.box-login').removeClass('show');
        });

        /*  go up */
        $('.gotop').on('click', function() {
            $('html,body').stop().animate({scrollTop:0 }, 500);
        });

        /*  imputes bonitos  */
        $('input, select, textarea').on('focus', function() {
            $(this).parent().addClass('focus');
        }).on('blur', function(e){
            $(this).parent().removeClass('focus');
        });

        resizeImg($('.thumbnail .imag'));

        autosize($('textarea'));
    }
};
